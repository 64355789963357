import React from 'react';
import { useNavigate } from 'react-router-dom';

import Settings from './Settings';
import Notifications from './Notifications';
import Chats from './Chats';

import header_logo from './Assets/header/header_logo.png';
import header_settings from './Assets/header/header_settings.png';
import header_notifications from './Assets/header/header_notifications.png';
import header_chats from './Assets/header/header_chats.png';
import header_logout from './Assets/header/header_logout.png';
import header_login from './Assets/header/header_login.png';

import './Header.css'

//revision 20240418

function Header({ state, setState }) {

    const history = useNavigate();

    const handleLogoClick = () => {
        if (window.location.pathname !== '/') history('/');
    };

    const handleSettings = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Settings state={state} setState={setState} />, modaltype: 'home' }))
    }

    const handleNotificationsClick = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Notifications state={state} setState={setState} />, modaltype: 'home' }))
    }

    const handleChatsClick = () => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <Chats state={state} setState={setState} />, modaltype: 'home' }))
    }

    function handleLoginClick() {
        history('/authscreen');
    }

    async function handeLogoutClick() {
        setState(state => ({
            ...state, user: {
                userid: '',
                usertoken: '',
                usertype: 0,
                useremail: '',
                username: '',
                userprofilepicture: '',
                userbiography: '',
            }
        }))
        await localStorage.removeItem('user')
    }

    if (state.user.userid == '') {
        return (
            <div className='header'>
                <div className='header_bar'>
                    <div className='header_item' onClick={(e) => handleLogoClick(e)}>
                        <img
                            className='header_logo'
                            src={header_logo}
                            alt='logo'
                            loading='eager'
                            height='1000'
                            width='1000'
                        />
                    </div>
                    <div className='header_item' onClick={handleLoginClick}>
                        <img className='header_icon' src={header_login} />
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className='header'>
                <div className='header_bar'>
                    <div className='header_item' onClick={(e) => handleLogoClick(e)}>
                        <img
                            className='header_logo'
                            src={header_logo}
                            alt='logo'
                            loading='eager'
                            height='1000'
                            width='1000'
                        />
                    </div>
                    <div className='header_item' onClick={(e) => handleSettings()}>
                        <img className='header_icon' src={header_settings} />
                    </div>
                    <div className='header_item' onClick={(e) => handleNotificationsClick()}>
                        <img className='header_icon' src={header_notifications} />
                        {
                            state.notificationscount !== 0 && <div className='header_notificationscount'>{state.notificationscount}</div>
                        }
                    </div>
                    <div className='header_item' onClick={(e) => handleChatsClick()}>
                        <img className='header_icon' src={header_chats} />
                        {
                            state.chatscount !== 0 && <div className='header_chatscount'>{state.chatscount}</div>
                        }
                    </div>
                    <div className='header_item' onClick={handeLogoutClick}>
                        <img className='header_icon' src={header_logout} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;