import React, { useState, useEffect } from 'react';
import axios from 'axios';
import configData from '../Config.json';
import moment from 'moment';
import Spinner from '../Spinner';
import Title from '../Title';

import './Maintenance.css';

function Maintenance({ state, setState }) {

    const [expiredlistings, setExpiredlistings] = useState([]);
    const [expiredlistingsresult, setExpiredlistingsresult] = useState('');
    const [expiredlistingsloading, setExpiredlistingsloading] = useState(false);

    const [orphanfiles, setOrphanfiles] = useState([]);
    const [orphanfilesresult, setOrphanfilesresult] = useState('');
    const [orphanfilesloading, setOrphanfilesloading] = useState(false);

    const [sqlfileintegrityresult, setSqlfileintegrityresult] = useState('');
    const [sqlfileintegrityloading, setSqlfileintegrityloading] = useState(false);

    const [mediaintegrity, setMediaintegrity] = useState([]);
    const [mediaintegrityresult, setMediaintegrityresult] = useState('');
    const [mediaintegrityloading, setMediaintegrityloading] = useState(false);

    const [menuresult, setMenuresult] = useState('');
    const [menuloading, setMenuloading] = useState(false);

    const getExpiredListings = () => {
        console.log('Trying to get expired listings');

        setExpiredlistingsresult(null);
        setExpiredlistingsloading(true);

        const data = {
            countryid: state.selectedcountry
        };

        axios.post(configData.SECUREURL + configData.ADMINMGETEXPIREDLISTINGS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Expired listings data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setExpiredlistings(res.data)
            }
            else {
                setExpiredlistingsresult('Error')
            }
        }).catch(() => {
            setExpiredlistingsresult('Error')
        }).finally(() => {
            setExpiredlistingsloading(false);
        })
    }

    const removeExpiredListings = () => {
        console.log('Trying to remove expired listings');

        setExpiredlistingsresult(null);
        setExpiredlistingsloading(true);

        const data = {
            countryid: state.selectedcountry,
            expiredlistings: expiredlistings
        };

        axios.post(configData.SECUREURL + configData.ADMINMREMOVEEXPIREDLISTINGS, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Remove expired listings data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setExpiredlistingsresult('Removed')
                    setExpiredlistings([])
                }
                else {
                    setExpiredlistingsresult(res.data.data)
                }
            }
            else {
                setExpiredlistingsresult('Error')
            }
        }).catch(() => {
            setExpiredlistingsresult('Error')
        }).finally(() => {
            setExpiredlistingsloading(false);
        })
    }

    const getOrphanFiles = () => {
        console.log('Trying to get orphan files');

        setOrphanfilesresult(null);
        setOrphanfilesloading(true);

        const data = {
            countryid: state.selectedcountry
        };

        axios.post(configData.SECUREURL + configData.ADMINMGETORPHANFILES, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Orphan files data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setOrphanfiles(res.data)
            }
            else {
                setOrphanfilesresult('Error')
            }
        }).catch(() => {
            setOrphanfilesresult('Error')
        }).finally(() => {
            setOrphanfilesloading(false);
        })
    }

    const removeOrphanFiles = () => {
        console.log('Trying to remove orphan files');

        setOrphanfilesresult(null);
        setOrphanfilesloading(true);

        const data = {
            countryid: state.selectedcountry,
            orphanfiles: orphanfiles
        };

        axios.post(configData.SECUREURL + configData.ADMINMREMOVEORPHANFILES, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Remove orphan files data received')
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setOrphanfilesresult('Removed')
                    setOrphanfiles([])
                }
                else {
                    setOrphanfilesresult(res.data.data)
                }
            }
            else {
                setOrphanfilesresult('Error')
            }
        }).catch(() => {
            setOrphanfilesresult('Error')
        }).finally(() => {
            setOrphanfilesloading(false);
        })
    }

    const getSqlFileIntegrity = () => {
        console.log('Trying to get sql file integrity');

        setSqlfileintegrityresult('')
        setSqlfileintegrityloading(true);

        const data = {
            countryid: state.selectedcountry,
        };

        axios.post(configData.SECUREURL + configData.ADMINMGETSQLFILEINTEGRITY, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Sql/file integrity data received')
            console.log(res.data)
            if (res.data.code == 1) {
                //setSqlfileintegrityresult(res.data.data)
            }
            else {
                setSqlfileintegrityresult('Error')
            }
        }).catch(() => {
            setSqlfileintegrityresult('Error')
        }).finally(() => {
            setSqlfileintegrityloading(false);
        })
    }

    const getMediaIntegrity = () => {
        console.log('Trying to get media intergrity');
        setMediaintegrityloading(true);

        const data = {
            countryid: state.selectedcountry,
        };

        axios.post(configData.SECUREURL + configData.ADMINMGETMEDIAINTEGRITY, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Media integrity data received')
            console.log(res.data)
            if (res.data instanceof Array) {
                setMediaintegrity(res.data)
            }
        }).catch(() => {
        }).finally(() => {
            setMediaintegrityloading(false);
        })
    }

    const checkMenu = () => {
        console.log('Trying to check menu');
        setMenuloading(true);

        const data = {
            countryid: state.selectedcountry,
        };

        axios.post(configData.SECUREURL + configData.ADMINMCHECKMENU, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Menu check data received')
            console.log(res.data)
            setMenuresult(res.data)
        }).catch(() => {
            setMenuresult('Menu check error')
        }).finally(() => {
            setMenuloading(false);
        })
    }

    const updateMenuCount = () => {
        console.log('Trying to update menu count');
        setMenuloading(true);

        const data = {
            countryid: state.selectedcountry,
        };

        axios.post(configData.SECUREURL + configData.ADMINMUPDATEMENU, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Update menu count data received')
            console.log(res.data)
            setMenuresult(res.data)
        }).catch(() => {
            setMenuresult('Error')
        }).finally(() => {
            setMenuloading(false);
        })
    }

    const sortMenu = () => {
        console.log('Trying to sort menu');
        setMenuloading(true);

        const data = {
            countryid: state.selectedcountry,
        };

        axios.post(configData.SECUREURL + configData.ADMINMSORTMENU, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log('Update menu count data received')
            console.log(res.data)
            setMenuresult(res.data)
        }).catch(() => {
            setMenuresult('Error')
        }).finally(() => {
            setMenuloading(false);
        })
    }

    return (
        <div className='adminmaintenance'>
            <Title text='Maintenance' />

            <div className='adminmaintenance_section'>
                <div className='adminmaintenance_sectiontitle'>Expired Listings</div>
                <div className='adminmaintenance_sectiondescription'>(Listings that exceeded expiry days)</div>

                <div className='adminmaintenance_buttons'>
                    <div className='adminmaintenance_buttoncontainer'>
                        <button
                            className='adminmaintenance_getbutton'
                            onClick={() => getExpiredListings()}
                            disabled={expiredlistingsloading}
                        >
                            Get expired listings
                        </button>
                    </div>
                    <div className='adminmaintenance_buttoncontainer'>
                        <button
                            className='adminmaintenance_fixbutton'
                            onClick={() => removeExpiredListings()}
                            disabled={expiredlistingsloading}
                        >
                            Remove expired listings
                        </button>
                    </div>
                </div>

                <div className='adminmaintenance_result'>
                    <Spinner loading={expiredlistingsloading} size={20} />
                    {expiredlistingsresult && expiredlistingsresult}
                </div>

                {
                    expiredlistings.length > 0 &&
                    <table className='adminmaintenance_table'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Filename</th>
                                <th>Userid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {expiredlistings.map((listing, index) => (
                                <tr key={index}>
                                    <td>{moment(listing.date).fromNow()}</td>
                                    <td>{listing.filename}</td>
                                    <td>{listing.userid}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>

            <div className='adminmaintenance_section'>
                <div className='adminmaintenance_sectiontitle'>SQL/File Integrity</div>
                <div className='adminmaintenance_sectiondescription'>(Sql entries that dont have doc files available)</div>

                <div className='adminmaintenance_buttons'>
                    <div className='adminmaintenance_buttoncontainer'>
                        <button
                            className='adminmaintenance_getbutton'
                            onClick={() => getSqlFileIntegrity()}>
                            Get SQL/File Integrity
                        </button>
                    </div>
                    <div className='adminmaintenance_buttoncontainer'>
                        <button
                            className='adminmaintenance_fixbutton'
                            onClick={() => setSqlfileintegrityresult('Button does nothing')}>
                            Fix Xml integrity
                        </button>
                    </div>
                </div>
            </div>

            <div className='adminmaintenance_result'>
                <Spinner loading={sqlfileintegrityloading} size={20} />
                {sqlfileintegrityresult && sqlfileintegrityresult}
            </div>

            <div className='adminmaintenance_section'>
                <div className='adminmaintenance_sectiontitle'>Media Integrity</div>
                <div className='adminmaintenance_sectiondescription'>(Doc files that have missing media files)</div>

                <div className='adminmaintenance_buttons'>
                    <div className='adminmaintenance_buttoncontainer'>
                        <button
                            className='adminmaintenance_getbutton'
                            onClick={() => getMediaIntegrity()}>
                            Get Media Integrity
                        </button>
                    </div>
                    <div className='adminmaintenance_buttoncontainer'>
                        <button
                            className='adminmaintenance_fixbutton'
                            onClick={() => setMediaintegrityresult('button does nothing')}>
                            Fix media integrity
                        </button>
                    </div>
                </div>

                <div className='adminmaintenance_result'>
                    <Spinner loading={mediaintegrityloading} size={20} />
                    {mediaintegrityresult && mediaintegrityresult}
                </div>

                {
                    mediaintegrity.length > 0 &&
                    <table className='adminmaintenance_table'>
                        <thead>
                            <tr>
                                <th>Filename</th>
                                <th>Media</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mediaintegrity.map((media, index) => (
                                <tr key={index}>
                                    <td>{media.filename}</td>
                                    <td>{media.media}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>

            <div className='adminmaintenance_section'>
                <div className='adminmaintenance_sectiontitle'>Orphan Files</div>
                <div className='adminmaintenance_sectiondescription'>(Files that dont have database records)</div>

                <div className='adminmaintenance_buttons'>
                    <div className='adminmaintenance_buttoncontainer'>
                        <button
                            className='adminmaintenance_getbutton'
                            onClick={() => getOrphanFiles()}
                            disabled={orphanfilesloading}
                        >
                            Get Orphan files
                        </button>
                    </div>
                    <div className='adminmaintenance_buttoncontainer'>
                        <button
                            className='adminmaintenance_fixbutton'
                            onClick={() => removeOrphanFiles()}
                            disabled={orphanfilesloading}
                        >
                            Remove orphan files
                        </button>
                    </div>
                </div>

                <div className='adminmaintenance_result'>
                    <Spinner loading={expiredlistingsloading} size={20} />
                    {orphanfilesresult && orphanfilesresult}
                </div>

                {
                    orphanfiles.length > 0 &&
                    <table className='adminmaintenance_table'>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Filename</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orphanfiles.map((files, index) => (
                                    <tr key={'files' + index}>
                                        <td>{files.type}</td>
                                        <td>{files.filename}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                }
            </div>

            <div className='adminmaintenance_section'>
                <div className='adminmaintenance_sectiontitle'>Menu Maintenance</div>
                <div className='adminmaintenance_sectiondescription'>(Menu health and maintenance)</div>

                <div className='adminmaintenance_buttons'>
                    <div className='adminmaintenance_buttoncontainer'>
                        <button
                            className='adminmaintenance_getbutton'
                            onClick={() => checkMenu()}>
                            Check Menu
                        </button>
                    </div>
                    <div className='adminmaintenance_buttoncontainer'>
                        <button
                            className='adminmaintenance_fixbutton'
                            onClick={() => updateMenuCount()}>
                            Update Menu Count
                        </button>
                    </div>
                    <div className='adminmaintenance_buttoncontainer'>
                        <button
                            className='adminmaintenance_fixbutton'
                            onClick={() => sortMenu()}>
                            Sort Menu
                        </button>
                    </div>
                </div>

                <Spinner loading={menuloading} size={20} />

                <div className='adminmaintenance_result'>
                    {JSON.stringify(menuresult)}
                </div>
            </div>
        </div >
    )
}

export default Maintenance;