import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import axios from 'axios';
import configData from './Config.json';
import { isUnicode } from './Utils/text.js';

import Title from './Title.js';

import chat_sendmessage from './Assets/chat/chat_sendmessage.png';

import './Chat.css';

//Revision 20240424

function Chat({ state, setState, userid, username }) {
    const [chathistory, setChathistory] = useState([]);

    const [message, setMessage] = useState('');
    const [messagedirectionrtl, setMessagedirectionrtl] = useState(false);
    const [inputHeight, setInputHeight] = useState(40);
    const [messageloading, setMessageloading] = useState(false);

    const [options, setOptions] = useState(null);
    const [removeconfirmation, setRemoveConfirmation] = useState(null);

    const [error, setError] = useState('');

    const inputRef = useRef(null);
    const scrollRef = useRef(null);

    const optionsRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (optionsRef.current && !optionsRef.current.contains(event.target)) {
                setOptions(false);
                setRemoveConfirmation(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionsRef]);

    useEffect(() => {
        getChat();
        clearChatsRead();
        const chatinterval = setInterval(() => {
            startGettingChat(userid)
        }, configData.GETCHATINTERVAL);
        return () => { clearInterval(chatinterval); };
    }, [])

    function getChat() {
        const data = {
            userid: state.user.userid,
            chatid: userid,
            lasttimestamp: 0
        };
        axios.post(configData.SECUREURL + configData.GETCHAT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setChathistory(res.data.data);
                    focusInput();
                    scrollToEnd();
                }
            }
        }).catch(() => {
            setError('Error fetching chat')
        }).finally(() => {
        })
    }

    function clearChatsRead() {
        const data = {
            userid: state.user.userid,
            chatid: userid,
        };
        axios.post(configData.SECUREURL + configData.CLEARCHATSREAD, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
        }).catch((err) => {
            console.log(err)
        })
    }

    const scrollToEnd = () => {
        setTimeout(() => {
            scrollRef.current.scrollTo({
                top: scrollRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }, 10);
    };

    const focusInput = () => {
        setTimeout(() => {
            inputRef.current.focus();
        }, 10);
    };

    function startGettingChat(chat) {
        let lasttimestamp = 0;

        if (chat.length) {
            lasttimestamp = chathistory[chat.length - 1].timestamp;
        }
        const data = {
            userid: state.user.userid,
            chatid: chat.userid,
            lasttimestamp: lasttimestamp
        };
        axios.post(configData.SECUREURL + configData.GETCHAT, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    setChathistory(res.data.data);
                }
                else if (res.data.code === 2) {
                    if (res.data.data instanceof Array) {
                        setChathistory([...chat, ...res.data.data])
                        scrollToEnd();
                    }
                }
            }
        }).catch(() => {
        })
    }

    useEffect(() => {
        const textarea = inputRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
        setInputHeight(textarea.scrollHeight);
    }, [message])

    const sendMessage = () => {
        setMessageloading(true)
        setError('')

        if (message == '') {
            return
        }

        const data = {
            fromuserid: state.user.userid,
            touserid: userid,
            message: message,
        };
        axios.post(configData.SECUREURL + configData.SENDMESSAGE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            console.log(res.data)
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    const currentTimestamp = Math.floor(Date.now() / 1000);
                    setChathistory([...chathistory, { fromme: 1, message: message, status: 0, timestamp: currentTimestamp }])
                    chathistory[chathistory.length - 1].status = 1;
                    setError('');
                    setMessage('')
                }
            }
            scrollToEnd();
        }).catch(() => {
            setError('Couldn\'t send message');
        }).finally(() => {
            setMessageloading(false)
        })
    }

    const renderStatusIndicator = (status) => {
        switch (status) {
            case 1:
                return 'read';
            default:
                return null;
        }
    };

    const handleInput = e => {
        setMessage(e.target.value)

        if (isUnicode(e.target.value)) {
            setMessagedirectionrtl(true)
        }
        else {
            setMessagedirectionrtl(false)
        }
    }

    const handleOptions = (chat) => {
        setOptions(chat);
    };

    const handleCancel = () => {
        setOptions(false);
        setRemoveConfirmation(false);
    };

    const handleConfirmation = (chat) => {
        setOptions(false);
        setRemoveConfirmation(chat);
    };

    const handleRemoveMessage = (chatitem) => {
        const data = {
            userid: state.user.userid,
            chatid: userid,
            timestamp: chatitem.timestamp
        };
        axios.post(configData.SECUREURL + configData.REMOVEMESSAGE, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "userid": state.user.userid,
                "usertoken": state.user.usertoken
            },
        }).then(res => {
            if (res.data instanceof Object) {
                if (res.data.code === 1) {
                    const updatedChat = chathistory.filter((chat) => chat.timestamp !== chatitem.timestamp);
                    setChathistory(updatedChat);
                    setRemoveConfirmation(false);
                }
            }
        }).catch(() => {
            setError('Couldn\'t remove message')
        }).finally(() => {
            setRemoveConfirmation(null)
        })
    };

    return (
        <div className='chat'>
            <Title text={username} />
            <div
                className='chat_panel'
                ref={scrollRef}
            >
                {
                    chathistory.length == 0 &&
                    <div className='chat_emptycontainer'>
                        <div className='chat_emptytext'>Chat Started</div>
                    </div>
                }
                {
                    chathistory.map((chat, index) => {
                        const timestampDate = new Date(chat.timestamp * 1000);
                        const showDate = index === 0 || !moment(timestampDate).isSame(chathistory[index - 1].timestamp * 1000, 'day');
                        return (
                            <React.Fragment key={'chat' + index}>
                                {showDate && (
                                    <div className='chat_date'>
                                        {moment(timestampDate).format('dddd MMMM D, YYYY')}
                                    </div>
                                )}
                                <div className={chat.fromme ? 'chat_fromme' : 'chat_fromyou'}>
                                    <div className={chat.fromme ? 'chat_frommeoptions' : 'chat_fromyouoptions'}>
                                        <div className='chat_leftoption'>
                                            <span className='chat_time'>{moment(timestampDate).format('h:mm A')}</span>
                                        </div>
                                        <div className='chat_rightoption'>
                                            <span className='chat_optionbutton' onClick={() => handleOptions(chat)}>···</span>
                                        </div>
                                    </div>
                                    <div className='chat_chat'>{chat.message}</div>
                                </div>
                            </React.Fragment>
                        );
                    })
                }
                <div className='chat_laststatus'>{chathistory.length !== 0 && renderStatusIndicator(chathistory[chathistory.length - 1].status)}</div>
            </div>

            <div className='chat_inputcontainer'>
                <div className='chat_errorcontainer'>
                    {
                        error &&
                        <div className='chat_errortext'>{error}</div>
                    }
                </div>
                <div className='chat_inputgroup'>
                    <textarea
                        ref={inputRef}
                        className={messagedirectionrtl ? 'chat_inputtextarea chat_inputtextareartl' : 'chat_inputtextarea chat_inputtextarealtr'}
                        style={{ height: inputHeight }}
                        value={message}
                        onChange={e => handleInput(e)}
                        placeholder="Send a message..."
                    />
                    <div className='chat_sendmessagecontainer'>
                        <button
                            className='chat_sendmessagebutton'
                            onClick={sendMessage}
                            disabled={messageloading}
                        >
                            <img className='chat_sendmessageicon' src={chat_sendmessage} alt="Send Message" />
                        </button>
                    </div>
                </div>
            </div>

            {
                options && (
                    <div className='chat_modal'>
                        <div className='chat_modalcontent' ref={optionsRef}>
                            <div className='chat_modalremove' onClick={() => handleConfirmation(options)}>Remove Message</div>
                            <div className='chat_modalcancel' onClick={handleCancel}>Cancel</div>
                        </div>
                    </div>
                )
            }

            {
                removeconfirmation && (
                    <div className='chat_modal'>
                        <div className='chat_modalcontent' ref={optionsRef}>
                            <div className='chat_modalremove' onClick={() => handleRemoveMessage(removeconfirmation)}>Confirm?</div>
                            <div className='chat_modalcancel' onClick={handleCancel}>No</div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Chat;