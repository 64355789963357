import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from './Header.js';
import FiltersBar from './FiltersBar.js';
import Navigator from './Navigator';
import Listings from './Listings';
import Metatags from './Metatags.js';
import BottomNavigator from './BottomNavigator.js';
import Modal from './Modal.js';

import './Home.css';

function Home({ state, setState }) {
    const { countryname } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        if (countryname) {
            const filteredCountry = state.countries.find(country => country.dir === countryname);
            if (filteredCountry) {
                setState(state => ({ ...state, selectedcountry: filteredCountry.id }));
            }
        }
    }, [countryname])

    return (
        <div className='home'>
            <Metatags />
            <Header state={state} setState={setState} />
            <FiltersBar state={state} setState={setState} />
            <Navigator state={state} setState={setState} />
            <Listings state={state} setState={setState} />
            <BottomNavigator state={state} setState={setState} />
            <Modal state={state} setState={setState} />
        </div>
    )
}

export default Home;