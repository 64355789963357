import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import UpdatePage from "./UpdatePage";

import Countries from "./Countries";
import Home from "./Home";
import Listing from "./Listing";

import AuthScreen from "./AuthScreen";

import NoMatch from "./NoMatch";
import PrivacyPolicy from "./PrivacyPolicy";

import axios from 'axios';
import configData from './Config.json';

function App() {

  const countries = [
    { id: 17, name: 'Algeria', dir: 'algeria', menufile: 'algeriamenu' },
    { id: 13, name: 'Bahrain', dir: 'bahrain', menufile: 'bahrainmenu' },
    { id: 6, name: 'Egypt', dir: 'egypt', menufile: 'egyptmenu' },
    { id: 10, name: 'Iraq', dir: 'iraq', menufile: 'iraqmenu' },
    { id: 7, name: 'Jordan', dir: 'jordan', menufile: 'jordanmenu' },
    { id: 5, name: 'KSA', dir: 'ksa', menufile: 'ksamenu' },
    { id: 9, name: 'Kuwait', dir: 'kuwait', menufile: 'kuwaitmenu' },
    { id: 0, name: 'Lebanon', dir: 'lebanon', menufile: 'lebanonmenu' },
    { id: 16, name: 'Libya', dir: 'libya', menufile: 'libyamenu' },
    { id: 18, name: 'Morocco', dir: 'morocco', menufile: 'moroccomenu' },
    { id: 11, name: 'Oman', dir: 'oman', menufile: 'omanmenu' },
    { id: 14, name: 'Palestine', dir: 'palestine', menufile: 'palestinemenu' },
    { id: 4, name: 'Qatar', dir: 'qatar', menufile: 'qatarmenu' },
    { id: 25, name: 'Sudan', dir: 'sudan', menufile: 'sudanmenu' },
    { id: 3, name: 'Syria', dir: 'syria', menufile: 'syriamenu' },
    { id: 21, name: 'Tunisia', dir: 'tunisia', menufile: 'tunisiamenu' },
    { id: 2, name: 'UAE', dir: 'uae', menufile: 'uaemenu' },
    { id: 12, name: 'Yemen', dir: 'yemen', menufile: 'yemenmenu' }
  ];

  const initialState = {
    user: {
      userid: '',
      usertoken: '',
      usertype: 0,
      useremail: '',
      username: '',
      userprofilepicture: '',
      userbiography: '',
    },
    notifications: [],
    notificationscount: 0,
    chats: [],
    chatscount: 0,
    countries: countries,
    menu: [],
    districts: [],
    communities: [],
    types: [],
    agreements: [],
    selectedcountry: '',
    selecteddistrict: 100,
    selectedcommunity: 0,
    selectedtype: 0,
    selectedagreement: 0,
    selectedoffset: 0,
    selectedlisting: 0,
    listings: [],
    listingsloading: false,
    modalopen: false,
    modalcontent: null,
    modaltype: null
  }

  const [state, setState] = useState(initialState);

  const [versionlock, setVersionlock] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/manifest.json');
      const manifestData = await response.json();

      setState(state => ({
        ...state,
        version: manifestData.version
      }))

      const checkVersions = async () => {
        axios.post(configData.SECUREURL + configData.GETVERSION, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json;charset=UTF-8"
          },
        }).then((res) => {
          if (res.data == manifestData.version) {
            setVersionlock(false);
          } else {
            setVersionlock(true);
          }
        }).catch((err) => {
          console.log(err.response?.data?.message || err.message || 'Network error');
        });
      };

      await checkVersions();
      const intervalId = setInterval(checkVersions, 43200000); // every 12 hours
      return () => clearInterval(intervalId);
    };

    fetchData();

  }, []);

  // ----------------------Get local menu---------------------- //

  async function getLocalMenu() {
    const menu = await localStorage.getItem(state.selectedcountry.menufile);
    return {
      menu: menu != null ? JSON.parse(menu) : null
    }
  }
  const storeMenu = async (menufile, value) => {
    try {
      const menustring = JSON.stringify(value)
      await localStorage.setItem(menufile, menustring);
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (state.selectedcountry != null) {
      getLocalMenu().then(value => {
        if (value.menu != null) {
          setState(state => ({ ...state, menu: value.menu }));
        }
        const data = {
          countryid: state.selectedcountry
        };
        axios.post(configData.SECUREURL + configData.GETMENU, data, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            "userid": state.user.userid,
            "usertoken": state.user.usertoken
          },
        }).then(res => {
          if (res.data instanceof Array) {
            if (JSON.stringify(value.menu) !== JSON.stringify(res.data)) {
              setState(state => ({ ...state, menu: res.data }));
              let menufile = countries.find(country => country.id == state.selectedcountry);
              menufile = menufile ? menufile.menufile : null;
              storeMenu(menufile, res.data)
            }
          }
        }).catch((err) => {
          console.log(err.response?.data?.message || err.message || 'Network error');
        })
      }).catch((err) => {
        console.log(err.response?.data?.message || err.message || 'Network error');
      });
    }
  }, [state.selectedcountry]);

  useEffect(() => {
    let newDistrict = state.menu.find((district) => district.id === state.selecteddistrict)
    let communities = newDistrict?.communities || [];
    setState(state => ({ ...state, communities: communities }));
  }, [state.menu])

  // ----------------------Get listings---------------------- //

  useEffect(() => {
    if (state.selectedcountry !== '') {
      setState(state => ({ ...state, listingsloading: true }))

      const data = {
        countryid: parseInt(state.selectedcountry),
        districtid: parseInt(state.selecteddistrict),
        communityid: parseInt(state.selectedcommunity),
        typeid: state.selectedtype,
        agreementid: state.selectedagreement,
        offset: state.selectedoffset
      };

      axios.post(configData.SECUREURL + configData.GETLISTINGS, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        },
      })
        .then(res => {
          console.log(res.data)
          if (res.data.code === 1) {
            if (res.data.data instanceof Array) {
              if (state.selectedoffset == 0) setState(state => ({ ...state, listings: res.data.data }))
              else setState(state => ({ ...state, listings: [...state.listings, ...res.data.data] }))
            }
            if (res.data.meta instanceof Object) {
            }
          }
          else if (res.data.code === 0) {
            setState(state => ({ ...state, listings: [] }))
          }
        }).catch((err) => {
          console.log(err.response?.data?.message || err.message || 'Network error');
        }).finally(() => {
          setState(state => ({ ...state, listingsloading: false }))
        })
    }
  }, [state.selectedcountry, state.selecteddistrict, state.selectedcommunity, state.selectedtype, state.selectedagreement, state.selectedoffset]);

  // ----------------------Get Init---------------------- //
  async function getLocalPropertyTypesAgreements() {
    const propertyagreements = await localStorage.getItem('propertyagreements');
    const propertytypes = await localStorage.getItem('propertytypes');
    return {
      propertyagreements: propertyagreements ? JSON.parse(propertyagreements) : null,
      propertytypes: propertytypes ? JSON.parse(propertytypes) : null,
    }
  }
  const storeAgreements = async (value) => {
    try {
      const propertyagreementsstring = JSON.stringify(value)
      await localStorage.setItem('propertyagreements', propertyagreementsstring);
    } catch (e) {
      console.log(e)
    }
  }
  const storeTypes = async (value) => {
    try {
      const propertytypesstring = JSON.stringify(value)
      await localStorage.setItem('propertytypes', propertytypesstring);
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getLocalPropertyTypesAgreements().then(value => {
      if (value.propertyagreements != null) {
        setState(state => ({ ...state, agreements: value.propertyagreements }))
      }
      if (value.propertytypes != null) {
        setState(state => ({ ...state, types: value.propertytypes }))
      }
      axios.post(configData.SECUREURL + configData.GETINIT, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        },
      }).then(res => {
        if (res.data instanceof Object) {
          if (JSON.stringify(value.propertyagreements) !== JSON.stringify(res.data.propertyagreements)) {
            setState(state => ({ ...state, agreements: res.data.propertyagreements }))
            storeAgreements(res.data.propertyagreements);
          }
          else {
          }
          if (JSON.stringify(value.propertytypes) !== JSON.stringify(res.data.propertytypes)) {
            setState(state => ({ ...state, types: res.data.propertytypes }))
            storeTypes(res.data.propertytypes);
          }
          else {
          }
        }
      }).catch((err) => {
        console.log(err.response?.data?.message || err.message || 'Network error');
      })
    })
  }, []);

  // ----------------------Get User---------------------- //
  useEffect(() => {
    getLocalUser().then(value => {
      if (value.user != null) {
        validateUser(value.user.userid, value.user.usertoken)
      }
      else {
      }
    })
  }, []);

  async function getLocalUser() {
    const user = await localStorage.getItem('user');
    return {
      user: user != null ? JSON.parse(user) : null
    }
  }

  function validateUser(userid, usertoken) {
    const data = {
      userid: userid,
      usertoken: usertoken
    };
    axios.post(configData.SECUREURL + configData.VALIDATEUSER, data, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    }).then(res => {
      if (res.data instanceof Object) {
        if (res.data.code === 1) {
          setState(state => ({
            ...state, user: {
              userid: userid,
              usertoken: usertoken,
              usertype: res.data.data.usertype,
              useremail: res.data.data.useremail,
              username: res.data.data.username,
              userprofilepicture: res.data.data.userprofilepicture,
              userbiography: res.data.data.userbiography,
            }
          }))
          startGettingNotifications(userid);
          setInterval(() => {
            startGettingNotifications(userid)
          }, 120000);
        }
        else {
        }
      }
      else {
      }
    }).catch((err) => {
      console.log(err.response?.data?.message || err.message || 'Network error');
    })
  }

  function startGettingNotifications(userId) {
    const data = {
      userid: userId
    };
    axios.post(configData.SECUREURL + configData.GETNOTIFICATIONS, data, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "userid": state.user.userid,
        "usertoken": state.user.usertoken
      },
    }).then(res => {
      if (res.data instanceof Object) {
        if (res.data.code === 1) {
          setState(state => ({
            ...state,
            notificationscount: res.data.notificationscount,
            notifications: res.data.notifications,
            chatscount: res.data.chatscount
          })
          )
        }
      }
    }).catch((err) => {
      console.log(err.response?.data?.message || err.message || 'Network error');
    })
  }

  // ----------------------Get countries---------------------- //
  async function getLocalCountries() {
    const countries = await localStorage.getItem('countries');
    return {
      countries: countries !== null ? JSON.parse(countries) : null
    }
  }
  const storeCountries = async (value) => {
    try {
      const countriesstring = JSON.stringify(value)
      await localStorage.setItem('countries', countriesstring);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getLocalCountries().then(value => {
      if (value.countries != null) {
        setState(state => ({ ...state, countries: value.countries }))
      }
      axios.post(configData.SECUREURL + configData.GETCOUNTRIES, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "userid": state.user.userid,
          "usertoken": state.user.usertoken
        },
      }).then(res => {
        if (res.data instanceof Array) {
          if (JSON.stringify(value.countries) !== JSON.stringify(res.data)) {
            setState(state => ({ ...state, countries: res.data }))
            storeCountries(res.data);
          }
          else {
          }
        }
        else {
        }
      }).catch((err) => {
        console.log(err.response?.data?.message || err.message || 'Network error');
      })
    }).catch((err) => {
      console.log(err.response?.data?.message || err.message || 'Network error');
    });
  }, []);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setState(state => ({ ...state, modalopen: false, modalcontent: null }));
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, []);

  return (
    versionlock ?
      <BrowserRouter>
        <Routes>
          <Route index element={<UpdatePage />} />
        </Routes>
      </BrowserRouter>
      :
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" >
              <Route index element={<Countries state={state} setState={setState} />} />
              <Route path="/:countryname/:listingid" element={<Listing state={state} setState={setState} />} />
              <Route path="/:countryname" element={<Home state={state} setState={setState} />} />
              <Route path="authscreen" element={<AuthScreen state={state} setState={setState} />} />
              <Route path="privacypolicy" element={<PrivacyPolicy />} />
              <Route path="*" element={<NoMatch reason='Looks like you found a page that does not exist' />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;