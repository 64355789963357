import React, { useEffect, useState } from 'react';
import Title from './Title';
import axios from 'axios';
import moment from 'moment';
import configData from './Config.json';
import EditListing from './EditListing.js';

import './MyListings.css'

// 20240419

function MyListings({ state, setState }) {
    const [mylistings, setMylistings] = useState([]);
    const [searchproperty, setSearchproperty] = useState('');

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState('');

    useEffect(() => {
        setLoading(true);
        setResult('');

        const timeoutId = setTimeout(() => {
            const data = {
                countryid: state.selectedcountry,
                userid: state.user.userid,
                searchstring: searchproperty,
            };
            axios.post(configData.SECUREURL + configData.GETMYLISTINGS, data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "userid": state.user.userid,
                    "usertoken": state.user.usertoken
                },
            }).then(res => {
                if (res.data && res.data.code === 1) {
                    setMylistings(res.data.data);
                    if (res.data.data.length === 0) {
                        setResult('No listings found.');
                    }
                } else {
                    setResult('No listings available.');
                }
            }).catch(() => {
                console.error('Couldn\'t fetch')
                setResult('Error fetching listings')
            }).finally(() => {
                setLoading(false)
            })
        }, 1500);

        return () => clearTimeout(timeoutId);
    }, [searchproperty]);

    const handleEdit = (listingid) => {
        setState(state => ({ ...state, modalopen: true, modalcontent: <EditListing state={state} setState={setState} listingid={listingid} />, modaltype: 'editlisting' }))
    }

    return (
        <div className='myposts'>
            <Title text='My Listings' />

            <div className='mylistings_searchinputgroup'>
                <input
                    className='mylistings_searchinput'
                    type='text'
                    value={searchproperty}
                    placeholder='Search'
                    onChange={e => setSearchproperty(e.target.value)}
                />
            </div>

            {
                loading ?
                    <div className='mylistings_loading'>
                        <div className='mylistings_loadingtext'>Loading...</div>
                    </div>
                    :
                    (
                        <table className='mylistings_table'>
                            <thead>
                                <tr>
                                    <th>
                                        <input type="checkbox" />
                                    </th>
                                    <th>ID</th>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Agreement</th>
                                    <th>Community</th>
                                    <th>Description</th>
                                    <th>Views</th>
                                </tr>
                            </thead>
                            <tbody>
                                {result && <tr><td colSpan={7} className='mylistings_nolistings'>{result}</td></tr>}
                                {mylistings.map((listing, index) => (
                                    <tr key={'listing' + index}
                                        className='mylistings_listing'
                                        onClick={() => handleEdit(listing.id)}>
                                        <td>
                                            <input type="checkbox" name={'listing' + index} />
                                        </td>
                                        <td>{listing.id}</td>
                                        <td>{moment(listing.timestamp * 1000).format('MMMM Do YYYY, h:mm:ssA')}</td>
                                        <td>{listing.type}</td>
                                        <td>{listing.agreement}</td>
                                        <td>{listing.community}</td>
                                        <td>{listing.description.length > 100 ? listing.description.slice(0, 100) + '...' : listing.description}</td>
                                        <td>{listing.counter}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
            }
        </div>
    )
}

export default MyListings;