import React, { useEffect } from 'react';

import modal_closebutton from './Assets/modal/modal_closebutton.png';

import './Modal.css';

//revision 20240928

const Modal = ({ state, setState }) => {
  let modalOverlayClass;
  let modalContentClass;

  switch (state.modaltype) {
    case 'listings':
      modalOverlayClass = 'modal_overlay_listings';
      modalContentClass = 'modal_content_listings';
      break;
    case 'home':
      modalOverlayClass = 'modal_overlay_home';
      modalContentClass = 'modal_content_home';
      break;
    case 'mediagallery':
      modalOverlayClass = 'modal_overlay_mediagallery';
      modalContentClass = 'modal_content_mediagallery';
      break;
    case 'profile':
      modalOverlayClass = 'modal_overlay_profile';
      modalContentClass = 'modal_content_profile';
      break;
    case 'comments':
      modalOverlayClass = 'modal_overlay_comments';
      modalContentClass = 'modal_content_comments';
      break;
    default:
      modalOverlayClass = 'modal_overlay_home';
      modalContentClass = 'modal_content_home';
  }

  useEffect(() => {
    if (state.modalopen) {
      document.body.classList.add('modal_removeoverflow');
    } else {
      document.body.classList.remove('modal_removeoverflow');
    }
  }, [state.modalopen]);

  if (!state.modalopen) return null;

  const handleClose = () => {
    setState(state => ({ ...state, modalopen: false, modalcontent: null, modaltype: null }));
  }

  return (
    <div className={modalOverlayClass} onClick={handleClose}>
      <div className={modalContentClass} onClick={(e) => e.stopPropagation()}>
        <div className="modal_closebutton" onClick={handleClose}>
          <img className="modal_closebuttonimage" src={modal_closebutton} alt="Close" />
        </div>
        {state.modalcontent}
      </div>
    </div>
  );
};

export default Modal;